h1 {
  font-size: 52px;
}

h2 {
  font-size:28px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 7px;
}

.subtitle {
  font-size: 20px;
}