.sections {
  padding: 70px 0 50px 0;
  .content {
    padding-top: 20px;
  }
}

.bg-white {
  background-color: #fff;
}

.bg-dark {
  color: #fafafa;
  background-color: #626262;
}

.feature {
  text-align: center;
  margin-bottom: 25px;
}

.separator {
  width: 35px;
  height: 2px;
  display: block;
  margin: 25px auto 19px auto;
  background-color: #6d5cae;
}

.icon-feature {
  font-size: 60px;
  color: #6d5cae;
}

#contact {
  .form-group {
    margin-bottom: 25px;
  }
}

.footer {
  padding: 20px 0;
}
