.btn-uc {
  text-transform: uppercase;
  font-weight: 600;
}

.btn {
  min-width: 150px;
  line-height: 26px;
}

nav .btn {
  min-width: 0;
  line-height: 22px;
}
